// import { useTonAddress } from "@tonconnect/ui-react";
import React, { useContext, useEffect, useState } from "react";
import { MdDoubleArrow } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { UserContext } from "../../../context/UserContext";
import airDropMe from "../../../media/airDropMe.png";
import profileIcon from "../../../media/profile_Image.png";
import v3PremiumTick from "../../../media/v3_coffeeTick.png";
import { rankToStringMapping } from "../../../utils/constants";
import { truncateName } from "../../../utils/helpers";
import styles from "./ProfileBar.module.css";

const ProfileBar = ({ setIsSettings, isSettingsShown }) => {
  // const [showConnect, setShowConnect] = useState(false);
  const [barWidth, setBarWidth] = useState(0);

  // showConnectWallet, imported from UserContext below, is not used in this component
  const { user } = useContext(UserContext);
  // const { notification } = useContext(AuthContext);
  const { isPremiumUser } = user;
  // kycCompleted, imported from UserContext below, is not used in this component
  const { jwt } = useContext(AuthContext);
  // const connectedAddress = useTonAddress();
  const navigate = useNavigate();

  useEffect(() => {
    if (!jwt) {
      navigate("/");
    }
  }, [jwt, navigate, user]);

  const handleBuyNowClick = () => {
    navigate("/privateSale");
  };
  const handleAirdropClick = () => {
    navigate("/airdrop");
  };
  useEffect(() => {
    const finalWidth =
      ((user.nextRankUpAt - user.invitesToRankUp) / user.nextRankUpAt) * 100; // Calculate the width
    setTimeout(() => {
      setBarWidth(finalWidth); // Set the final width after a small delay
    }, 100); // Small delay before starting the animation
  }, [user]);

  return (
    <div className={styles.welcomeContainer2}>
      <div className={styles.ProfileMainDiv}>
        <div
          style={{
            display: "flex",
            gap: "5px",
            width: "9.5rem",
            marginTop: "1rem",
          }}
        >
          <div className={styles.profileIconDiv}>
            <img
              src={profileIcon}
              alt="message icon"
              height="45px"
              width="45px"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div>
            <div className={styles.profileInfoDiv}>
              <span className={styles.profileName} style={{ fontSize: "9px" }}>
                @{truncateName(user.name, 8)}
              </span>
              {/* {kycCompleted && (
                <img
                  src={v3BlueTick}
                  alt="message icon"
                  height="20px"
                  width="20px"
                />
              )} */}
              {isPremiumUser && (
                <img
                  src={v3PremiumTick}
                  alt="message icon"
                  height="25px"
                  width="25px"
                  style={{ objectFit: "cover", marginRight: "-3px" }}
                />
              )}
            </div>
            <div style={{ width: "100px" }}>
              <div className={styles.profileRankInfo}>
                <span style={{ fontSize: "8px" }}>
                  {rankToStringMapping[user.rank]}
                </span>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "2px" }}
                >
                  {/*<span style={{ fontSize: "8px" }}>
                    {user.nextRankUpAt - user.invitesToRankUp}/
                    {user.nextRankUpAt}
                  </span>*/}
                  <MdDoubleArrow
                    className={styles.arrowIcon}
                    onClick={() => {
                      navigate("/friend?bonus=true");
                    }}
                  />
                </div>
              </div>
              <div className={styles.levelscore} style={{ width: "100%" }}>
                <div className={styles.progressBox}>
                  <div
                    className={styles.greenBar}
                    style={{
                      width: `${barWidth}%`,
                      maxWidth: "100%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "13rem",
            marginLeft: "0.5rem",
            position: "relative",
          }}
        >
          <img
            src={airDropMe}
            alt="slide bg"
            style={{
              width: "100%", // 80% of the viewport width
              position: "absolute",
              top: "0",
              left: "0",
            }}
            onClick={handleAirdropClick}
          />

          {/* {isPremiumUser ? (
            <div
              style={{
                position: "absolute",
                bottom: "2rem",
                left: "1rem",
              }}
            >
              <span
                style={{ fontSize: "0.7rem", fontFamily: "Zen Dots, Orbitron" }}
              >
                {user.pepeCredits.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                bottom: "1.2rem",
                left: "1rem",
              }}
            >
              <img
                alt="buy now img"
                style={{
                  width: "7.5rem",
                  height: "0.8rem",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
                onClick={handleBuyNowClick}
                src={buyNow}
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ProfileBar;
