import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import sports from "../../media/clubs & motors/dealer_comfort.png";
import Luxury from "../../media/clubs & motors/dealer_luxury.png";
import v3motorBikeImg from "../../media/clubs & motors/dealer_motorcycle.png";
import Jets from "../../media/clubs & motors/dealer_private_jets.png";
import v3Suvis from "../../media/clubs & motors/dealer_suvs.png";
import Yacht from "../../media/clubs & motors/dealer_yatch_club.png";
import dubai from "../../media/clubs & motors/frgobar_dubai.png";
import BEIJING from "../../media/clubs & motors/frogbar_beijing2.png";
import istanbul from "../../media/clubs & motors/frogbar_istanbul.png";
import london from "../../media/clubs & motors/frogbar_london.png";
import moscow from "../../media/clubs & motors/frogbar_moscow.png";
import mumbai from "../../media/clubs & motors/frogbar_mumbai.png";
import paris from "../../media/clubs & motors/frogbar_paris.png";
import rio from "../../media/clubs & motors/frogbar_RIO.png";
import roma from "../../media/clubs & motors/frogbar_roma.png";
import singapore from "../../media/clubs & motors/frogbar_singapore.png";
import v3GoldCoin from "../../media/silver-pepe-bg.png";
import "./club.css";

import { AuthContext } from "../../context/AuthContext";
import {
  clubIdxToNameMapping,
  clubLevelDetails,
  SERVER_ENDPOINT,
} from "../../utils/constants";
import { getReadableNumber } from "../../utils/helpers";

const imageMap = {
  dubai: dubai,
  beijing: BEIJING,
  mumbai: mumbai,
  istanbul: istanbul,
  paris: paris,
  london: london,
  roma: roma,
  rio: rio,
  moscow: moscow,
  singapore: singapore,
  la: moscow,
  miami: roma,
  Motorbike: v3motorBikeImg,
  SUVs: v3Suvis,
  Sports: sports,
  Luxury: Luxury,
  Jets: Jets,
  Yacht: Yacht,
};

const LevelUp = ({
  handlePopupClose,
  selectedClub,
  setUserClubs,
  userCredits,
  setUserCredits,
  selectedClubLevel,
}) => {
  const [loading, setLoading] = useState(false);
  const { jwt } = useContext(AuthContext);

  async function handleConfirm() {
    try {
      const resp = await fetch(`${SERVER_ENDPOINT}/v1/club/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ clubID: selectedClub.clubId }),
      });

      const parsedRes = await resp.json();
      if (resp.status === 200) {
        if (parsedRes.updatedData) {
          const clubs = parsedRes.updatedData.clubLevel.map((level, idx) => {
            return {
              clubId: idx,
              clubName: clubIdxToNameMapping[idx],
              isOnUpgrade: parsedRes.updatedData.isOnUpgrade[idx],
              isUnlocked: parsedRes.updatedData.isUnLocked[idx],
              returnAmount: parsedRes.updatedData.returnAmount[idx],
              upgradeStartAt: parsedRes.updatedData.timeOfStart[idx],
              clubLevel: level,
            };
          });

          setUserCredits(
            userCredits - clubLevelDetails[selectedClubLevel].invest
          );
          setUserClubs(clubs);
        } else throw new Error("Error updaing the club");
      } else {
        toast.error(parsedRes?.message || "Error occured during club upgrade");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error occured during club upgrade");
    } finally {
      setLoading(false);
      handlePopupClose();
    }
  }


  return (
    <div className="levelUpContainer">
      <p className="levelUpTitle">LEVEL UP</p>
      <div className="levelUpContent">
        <div className="">
          {/* <span className="BoxTitle">PEPE's Frogbar </span>
          <span className="LevlUpSubTitle">{selectedClub.clubName}</span> */}
          <img
            src={imageMap[selectedClub.clubName.toLowerCase()]}
            alt=""
            className="levelUpImg"
          />
        </div>
      </div>
      <div className="levelUpTextContent">
        <p className="textContentTitle">New investments:</p>
        <div className="info">
          <div className="inlineInfo">
            <span className="infoText">Time :</span>
            <span className="InfoText">
              {Math.floor(
                clubLevelDetails[selectedClub.clubLevel]?.duration / 60
              )}
              h {clubLevelDetails[selectedClub.clubLevel]?.duration % 60}m
            </span>
          </div>
          <div className="inlineInfo">
            <span className="infoText">ROI :</span>
            <span className="InfoText">
              {clubLevelDetails[selectedClub.clubLevel]?.roi}%
            </span>
          </div>
          <div className="inlineInfo">
            <span className="infoText">Investment:</span>
            <span
              className="InfoText"
              style={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              {getReadableNumber(
                clubLevelDetails[selectedClub.clubLevel]?.invest
              )}
              <img src={v3GoldCoin} alt="" height="20px" width="20px" />
            </span>
          </div>
          <div className="inlineInfo">
            <span className="infoText">Eligible Credits :</span>
            <span className="InfoText">{getReadableNumber(userCredits)}</span>
          </div>
        </div>
        <div className="Level_bottomSection">
          <button
            disabled={loading}
            onClick={handleConfirm}
            className="stripe-button"
          >
            {loading ? "Updating.." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LevelUp;
