import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast"; // For notifications
import { UserContext } from "../../../../context/UserContext";
import v3_Arrrow from "../../../../media/V3_arrow.png";
import { SERVER_ENDPOINT } from "../../../../utils/constants"; // API endpoint
import "./demo.css";

import downloadIcon from "../../../../media/download.png";
import playIcon from "../../../../media/play_color_logo.png";
import telegramIcon from "../../../../media/tg_color_logo.png";
import tweeterIcon from "../../../../media/x_color_logo.png";
import { truncateName } from "../../../../utils/helpers";

// Image mapping (like the old popup.jsx)
const getImage = {
  telegram: telegramIcon,
  playIcon: playIcon,
  tweeterIcon: tweeterIcon,
  download: downloadIcon,
};

const getCompletedImage = {
  telegram: telegramIcon,
  playIcon: playIcon,
  tweeterIcon: tweeterIcon,
};

// Helper function to format the reward
const formatReward = (reward) => {
  if (reward >= 1000000) {
    return `${Math.floor(reward / 1000000)}M`; // Convert to millions (M) without decimals
  } else if (reward >= 1000) {
    return `${Math.floor(reward / 1000)}k`; // Convert to thousands (K) without decimals
  }
  return reward; // Return the original value if it's less than 1000
};

const DemoPartner = ({
  partner,
  updateClaimStatus,
  onClose,
  isBitgit = false,
}) => {
  const { user, setUserCredits } = useContext(UserContext);
  const [taskStatuses, setTaskStatuses] = useState({}); // To track task completion

  useEffect(() => {
    if (partner && partner.tasks) {
      partner.tasks.forEach(async (task) => {
        const completed = await isTaskCompleted(task.task_id);
        setTaskStatuses((prev) => ({ ...prev, [task.task_id]: completed }));
      });
    }
  }, [partner]);

  const isTaskCompleted = async (taskId) => {
    try {
      const res = await fetch(
        `${SERVER_ENDPOINT}/v1/mapping/taskCompletedByUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            task_id: taskId,
            user_id: user.userID,
          }),
        }
      );
      const data = await res.json();
      return data.status ? true : false;
    } catch (error) {
      console.error("Error checking task completion status:", error);
      return false;
    }
  };

  const openLink = async (task) => {
    window.open(task.task_url, "_blank");
    try {
      const requestBody = {
        user_id: user.userID,
        task: {
          task_id: task.task_id,
          task_name: task.task_name,
          partner_id: partner.partner_id,
          credits: task.credits,
        },
      };
      const res = await fetch(`${SERVER_ENDPOINT}/v1/task/taskComplete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      let response = await res.json();
      if (response.status) {
        setTimeout(() => {
          setTaskStatuses((prev) => ({
            ...prev,
            [task.task_id]: true,
          }));
          toast.success(response.message);
        }, 3000);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClaimAll = async () => {
    const allTasksCompleted = Object.values(taskStatuses).every(
      (status) => status === true
    );

    if (!allTasksCompleted) {
      toast.error("Please complete all tasks to claim the reward.");
      return;
    }

    try {
      const creditRes = await fetch(
        `${SERVER_ENDPOINT}/v1/user/getPartnerCredits`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user.userID,
            partner_id: partner.partner_id,
          }),
        }
      );

      const creditData = await creditRes.json();

      if (creditData.status) {
        toast.success(creditData.message);
        updateClaimStatus(partner.partner_id);
        setUserCredits(creditData.newCreditsValue);

        onClose();
      } else {
        toast.error(creditData.message);
        onClose();
      }
      // } else {
      //   toast.error(data.message);
      //   onClose();
      // }
    } catch (error) {
      console.log(error);
    }
  };

  if (!partner) {
    return <div>No partner data available</div>; // Fallback message
  }

  return (
    <div
      style={{
        padding: "30px",
      }}
    >
      <p className="demo_Parteners_Title">
        {isBitgit ? "BITGET WALLET" : "PARTNER"}
      </p>
      <div className="partners_Content_Div">
        <div style={{ display: "flex", textAlign: "left", gap: "10px" }}>
          <div>
            <img
              src={partner.partner_image_url}
              alt={partner.partner_name}
              style={{ width: "6.25rem" }}
            />
          </div>
          <div>
            <span style={{ textTransform: "uppercase" }}>
              {isBitgit ? "" : partner.partner_name}
            </span>{" "}
            <br />
            <span>{partner.partner_description}</span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span>Reward available:</span>
                <br />
              </div>
              <div>
                <span
                  style={{
                    color: "gold",
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                  }}
                >
                  +{formatReward(partner.reward)}{" "}
                  {/* Use the formatted reward */}
                </span>
                <br />
                <span>{partner.end_time}</span> {/* Display dynamic end time */}
              </div>
            </div>
          </div>
        </div>
        <hr />
        {partner.tasks &&
          partner.tasks.map((task, index) => (
            <div
              key={index}
              className="othersTask"
              style={{ marginTop: "15px" }}
            >
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {/* Map the task image */}
                  <img
                    src={getImage[task.task_image]}
                    style={{ height: "55px" }}
                    alt={task.task_name}
                  />
                </div>
                <div>
                  <span>{truncateName(task.task_name, 30)}</span> <br />
                  <span>Status:</span>{" "}
                  <span style={{ color: "gold" }}>
                    {taskStatuses[task.task_id] ? "Completed" : "Available"}
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#c9c9c9",
                    }}
                    onClick={() => openLink(task)} // Handle task click
                  >
                    Go
                  </span>
                </div>
                <div>
                  <img src={v3_Arrrow} alt="Go to task" />
                </div>
              </div>
              <hr />
            </div>
          ))}
        <button className="stripe-button" onClick={handleClaimAll}>
          Claim All
        </button>
      </div>
    </div>
  );
};

export default DemoPartner;
