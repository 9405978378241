import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { UserContext } from "../../../context/UserContext";
import v3_Arrrow from "../../../media/V3_arrow.png";
import v3_hamsterCoin from "../../../media/v3_hamsterCoin.png";
import v3_tiktok from "../../../media/v3_tiktok.png";
import v3_xLogo from "../../../media/v3_xLogo.png";
import v3_YoutubeIcon from "../../../media/V3_youtube.png";
import v3_GreenTick from "../../../media/v3green-tick.png";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { getMobileOperatingSystem, truncateName } from "../../../utils/helpers";

function screenImg(screenName) {
  if (screenName === "youtube") return v3_YoutubeIcon;
  else if (screenName === "x") return v3_xLogo;
  else if (screenName === "partners") return v3_hamsterCoin;
  else return v3_tiktok;
}

const SwitchableTaskCard = ({ icons, title, screen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRedeemLoading, setIsRedeemLoading] = useState(false);
  const [blinkTele, setBlinkTele] = useState(false);
  const [blinkYt, setBlinkYt] = useState(false);
  const [blinkX, setBlinkX] = useState(false);
  const [tasks, setTasks] = useState([]);
  const { setUserCredits, user } = useContext(UserContext);
  const { jwt } = useContext(AuthContext);

  async function handleTaskCompletion(taskID, redirectUrl) {
    try {
      setIsRedeemLoading(true);
      const response = await fetch(`${SERVER_ENDPOINT}/v1/task/redeem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ taskID }),
      });
      const parsedResponse = await response.json();
      if (parsedResponse.status === "SUCCESS") {
        // put in timeout for adding delay in completion ui
        setTimeout(
          () =>
            setTasks((tasks) => {
              return tasks.map((task) => {
                if (task.taskID === parsedResponse.data.taskID) {
                  task.completed = true;
                }
                return task;
              });
            }),
          5000
        );
        setUserCredits(
          (credits) => credits + parseInt(parsedResponse.data.reward)
        );
      } else throw new Error("Failed to redeem task");
    } catch (error) {
      console.log(error);
    } finally {
      setIsRedeemLoading(false);
      if (getMobileOperatingSystem() == "iOS") {
        window.location.href = redirectUrl;
      } else {
        window.open(redirectUrl);
      }
      //   window.location.href = redirectUrl;
    }
  }

  useEffect(() => {
    async function fetchTasks() {
      try {
        setIsLoading(true);
        const response = await fetch(`${SERVER_ENDPOINT}/v1/task/list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ screen }),
        });
        const parsedResponse = await response.json();
        setTasks(parsedResponse.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchTasks();
  }, [screen]);

  return (
    <div>
      {tasks.map((task) => (
        <div
          onClick={() => handleTaskCompletion(task.taskID, task.url)}
          className="task_Card borderCard"
          style={{ width: "92vw", marginTop: "20px" }}
        >
          <div className="taskImg">
            <img src={screenImg(task.screen)} alt="" className="taskCardImg" />
          </div>
          <div className="task_text">
            <span className="text_1stSpan">
              {truncateName(task.name || "User", 28)}
            </span>{" "}
            <br />
            <span className="text_3drdSpan">
              Status:
              <span style={{ color: "#BFA75D" }}>
                {task.completed ? " Done" : " Available"}
              </span>
            </span>
          </div>
          <div className="task_Reward">
            <span
              style={{
                color: "#BFA75D",
                fontSize: "18px",
                letterSpacing: "1px",
              }}
            >
              + {task.reward / 1000}K
            </span>{" "}
            <br />
            <span style={{ fontSize: "10px" }}>Task Reward</span>
          </div>
          <div className="task_Icon">
            {task.completed ? (
              <img
                src={v3_GreenTick}
                alt=""
                style={{
                  height: "25px",
                  width: "25px",
                }}
              />
            ) : (
              <img src={v3_Arrrow} alt="" />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SwitchableTaskCard;
