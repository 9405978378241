import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../../../context/UserContext";
import tweeterIcon from "../../../media/V3_X.png";
import arrow from "../../../media/V3_arrow.png";
import telegramIcon from "../../../media/V3_tg.png";
import youtubeIcon from "../../../media/V3_youtube.png";
import playIcon from "../../../media/play_color_logo.png";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import "./Popup.css"; // Import the scoped CSS file

const getImage = {
  youtube: youtubeIcon,
  telegram: telegramIcon,
  playIcon: playIcon,
  tweeterIcon: tweeterIcon,
};

function Popup({ isOpen, onClose, partner, updateClaimStatus }) {
  const [showPopup, setShowPopup] = useState(false);
  const { user, setUserCredits } = useContext(UserContext);
  const [taskStatuses, setTaskStatuses] = useState({});

  const popupRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      // Delay adding the "show" class to ensure smooth animation
      setTimeout(() => setShowPopup(true), 50);
    } else {
      setShowPopup(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (partner && partner.tasks) {
      // For each task, check if it is completed and store the result
      partner.tasks.forEach(async (task) => {
        const completed = await isTaskCompleted(task.task_id);
        setTaskStatuses((prev) => ({ ...prev, [task.task_id]: completed }));
      });
    }
  }, [partner]);

  const isTaskCompleted = async (task) => {
    try {
      const res = await fetch(
        `${SERVER_ENDPOINT}/v1/mapping/taskCompletedByUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            task_id: task,
            user_id: user.userID,
          }),
        }
      );
      const data = await res.json();
      return data.status ? true : false;
    } catch (error) {
      console.error("Error checking task completion status:", error);
      return false;
    }
  };

  const openLink = async (task) => {
    window.open(task.task_url, "_blank");
    try {
      const requestBody = {
        user_id: user.userID,
        task: {
          task_id: task.task_id,
          task_name: task.task_name,
          partner_id: partner.partner_id,
          credits: task.credits,
        },
      };
      const res = await fetch(`${SERVER_ENDPOINT}/v1/task/taskComplete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      let response = await res.json();
      if (response.status) {
        setTimeout(() => {
          setTaskStatuses((prev) => ({
            ...prev,
            [task.task_id]: true,
          }));
          toast.success(response.message);
        }, 3000);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClaim = async () => {
    const allTasksCompleted = Object.values(taskStatuses).every(
      (status) => status === true
    );

    if (!allTasksCompleted) {
      toast.error("Please complete all tasks to claim the reward.");
      return;
    }

    try {
      const creditRes = await fetch(
        `${SERVER_ENDPOINT}/v1/user/getPartnerCredits`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user.userID,
            partner_id: partner.partner_id,
          }),
        }
      );

      const creditData = await creditRes.json();

      if (creditData.status) {
        toast.success(creditData.message);
        updateClaimStatus(partner.partner_id);
        setUserCredits(creditData.newCreditsValue);
        setTimeout(() => {
          onClose(); // Close the popup
        }, 300);
      } else {
        toast.error(creditData.message);
      }
    } catch (creditError) {
      console.log(creditError);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div
        ref={popupRef}
        className={`popup-container ${showPopup ? "show" : ""}`}
        style={{
          backgroundColor: "#1D1C1D",
          borderTopLeftRadius: "60px",
          borderTopRightRadius: "60px",
          borderTop: "5px solid yellow",
        }}
      >
        <hr style={{ border: "0.14rem solid #433f40", marginTop: "3rem" }} />
        <div style={{ display: "flex", padding: "1rem" }}>
          <img
            src={partner.partner_image_url}
            alt="Partner logo"
            className="popup-image"
            style={{ width: "6.5rem", height: "6.5rem", margin: "auto " }}
          />
          <div
            style={{
              textAlign: "left",
              color: "#b2b2b2",
              fontFamily: "roboto",
              margin: "auto 4rem auto 0.5rem",
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontWeight: "800", fontSize: "1.5rem" }}>
              HAMSTER KOMBAT
            </div>
            <div style={{ fontWeight: "800", fontSize: "1.15rem" }}>
              Hamster is a tap 2 earn TG Game on TON!
            </div>
            <div style={{ fontWeight: "800", fontSize: "1rem" }}>
              Reward available:
            </div>
          </div>
          <div
            style={{
              display: "flex",
              color: "#e4e278",
              alignItems: "end",
              fontSize: "1.4rem",
              margin: "0 1rem 0 0",
            }}
          >
            +500K
          </div>
        </div>
        <hr style={{ border: "0.14rem solid #433f40" }} />
        {partner.tasks.map((task, index) => (
          <div>
            <div
              key={index}
              onClick={() => openLink(task)}
              style={{ display: "flex", padding: "1rem" }}
            >
              <img
                src={getImage[task.task_image]}
                style={{
                  marginRight: task.task_id.endsWith("1") ? "2rem" : "1rem",
                }}
                alt={`${task.task_name} icon`}
              />
              <div
                style={{
                  textAlign: "left",
                  color: "#b2b2b2",
                  fontFamily: "roboto",
                  margin: "auto 4rem auto 0.5rem",
                  gap: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <div style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                  {task.label}
                </div>
                <div
                  style={{
                    display: "flex",
                    fontWeight: "800",
                    fontSize: "1.15rem",
                  }}
                >
                  Status:{" "}
                  <div
                    style={{
                      fontWeight: "800",
                      fontSize: "1.15rem",
                      color: taskStatuses[task.task_id] ? "green" : "red",
                      marginLeft: "8px",
                    }}
                  >
                    {taskStatuses[task.task_id] ? "Available" : "Completed"}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  color: "#B1A9A6",
                  fontSize: "1.6rem",
                  marginLeft: "5rem",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                GO
                <img src={arrow} style={{ margin: "auto 0 auto 2rem" }} />
              </div>
            </div>
            <hr style={{ border: "0.14rem solid #433f40" }} />
          </div>
        ))}

        <button
          className="popup-claim-btn"
          onClick={handleClaim}
          style={{
            marginTop: "2rem",
            backgroundColor: "#FFA114",
            width: "11rem",
            height: "4rem",
            fontSize: "1.2rem",
          }}
        >
          CLAIM ALL
        </button>
        {/* </div> */}
        {/* 
      
        <h1 className="popup-title">{partner.partner_name}</h1>
        <div className="popup-description">
          Accomplish all the tasks and <br />claim your reward
        </div>
        <div className="popup-icons">
          {partner.tasks.map((task, index) => (
            <div
              key={index}
              className="popup-icon-wrapper"
              onClick={() => openLink(task)}
            >
              <img
                src={
                  taskStatuses[task.task_id]
                    ? getCompletedImage[task.task_image]
                    : getImage[task.task_image]
                }
                alt={`${task.task_name} icon`}
              />
              <span className="popup-icon-text">{task.label}</span>
            </div>
          ))}
        </div>
        <button className="popup-claim-btn" onClick={handleClaim}>
          CLAIM {partner.reward}
        </button> */}
      </div>
    </div>
  );
}

export default Popup;
