import { animated, useSpring } from "@react-spring/web";
import { useTonAddress } from "@tonconnect/ui-react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ClubContext } from "../../../context/ClubContext.js";
import { HotelContext } from "../../../context/HotelContext.js";
import { MotorContext } from "../../../context/MotorContext";
import { UserContext } from "../../../context/UserContext";
import profileIcon from "../../../media/empty-profile-icon.png";
import Loader from "../../../media/Loader.svg";
import rank1Gif from "../../../media/rank1Gif.gif";
import rank2Gif from "../../../media/rank2Gif.gif";
import rank3Gif from "../../../media/rank3Gif.gif";
import {
  default as creditCoinGif,
  default as v3_GoldCoin,
} from "../../../media/silver-pepe-bg.png";
import bluecheck from "../../../media/v3_tick.png";
import useTelegramBackButton from "../../../utils/backButton.js";
import {
  SERVER_ENDPOINT,
  userClubToRankNameMapping,
} from "../../../utils/constants";
import { formatNumberWithCommas } from "../../../utils/formatedNumberWithComas.js";
import { getReadableNumber, truncateName } from "../../../utils/helpers";
import BottomButtons from "../../Shared/BottomNavigation/bottomButtons";
import ProfileBar from "../../Shared/ProfileBar/ProfileBar";
import WalletModal from "../WalletModal";
import styles from "./Stats.module.css";

const rankIcons = {
  1: rank1Gif,
  2: rank2Gif,
  3: rank3Gif,
  // 4: platinumIcon,
  // 5: diamondIcon,
  // 6: blueDiamondIcon,
  // 7: crownDiamondIcon,
};

const Stats = () => {
  useTelegramBackButton();
  const [activeTab, setActiveTab] = useState("weekly");
  const [page, setPages] = useState("stats");
  const [visible, setVisible] = useState(false);
  const [weeklyRanking, setWeeklyRanking] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [allTimeRanking, setAllTimeRanking] = useState([]);
  const [userDailyInfo, setUserDailyInfo] = useState(0);
  const [timer, setTimer] = useState({});
  const connectedAddress = useTonAddress();
  const { jwt } = useContext(AuthContext);
  const { user, userCredits, showConnectWallet, setShowConnectWallet } =
    useContext(UserContext);
  const { clubCredits, userClubs, setUserClubs, userStars, userClubRank } =
    useContext(ClubContext);
  const { userMotors, setUserMotors, motorCredits } = useContext(MotorContext);
  const { userHotels, setUserHotels, hotelCredits } = useContext(HotelContext);

  const [loading, setLoading] = useState(false);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const topAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(1%)",
      });
    },
    config: { duration: 300 },
  });
  const dataAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(1%)",
      });
    },
    config: { duration: 300 },
  });

  const bottomAnimation = useSpring({
    from: { transform: "translateY(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(1%)",
      });
    },
    config: { duration: 300 },
  });

  const leftAnimation = useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(0%)",
      });
    },
    config: { duration: 300 },
  });
  useEffect(() => {
    async function fetchStats() {
      try {
        const [onlineUserRes, metricsRes] = await Promise.all([
          fetch(`${SERVER_ENDPOINT}/v1/user/online`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }),
          fetch(`${SERVER_ENDPOINT}/v1/appmetric`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }),
        ]);
        const online = await onlineUserRes.json();
        const appMetrics = await metricsRes.json();
        if (online.status === "SUCCESS") setOnlineUsers(online.data.count);
        if (appMetrics.status === "SUCCESS") {
          appMetrics.data.forEach((appMetric) => {
            if (appMetric.name === "credits") setTotalCredits(appMetric.value);
            else if (appMetric.name === "players")
              setTotalUsers(appMetric.value);
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchRankings() {
      setLoading(true);
      try {
        const [allTimeRes, weeklyRes] = await Promise.all([
          fetch(`${SERVER_ENDPOINT}/v1/user/ranking`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }),
          fetch(`${SERVER_ENDPOINT}/v1/user/ranking/weekly`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }),
        ]);
        const weeklyParsed = await weeklyRes.json();
        const parsedAllTime = await allTimeRes.json();

        if (weeklyParsed.status === "SUCCESS") {
          setWeeklyRanking(weeklyParsed.data.ranking);
          setUserDailyInfo(weeklyParsed.data.user);
        }
        if (parsedAllTime.status === "SUCCESS") {
          setAllTimeRanking(parsedAllTime.data);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    fetchRankings();
    fetchStats();
    function updateTimer() {
      const duration = moment.duration(
        moment().utc().endOf("isoWeek").diff(moment().utc(), "seconds"),
        "seconds"
      );

      // Set the timer with the remaining time until the end of the week
      setTimer({
        days: Math.floor(duration.asDays()), // Get the number of full days remaining
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      });
    }
    const interval = setInterval(updateTimer, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.body}>
      <div className={styles.statContent}>
        <animated.div style={{ ...topAnimation }}>
          <div className={styles.pfBar} style={{ marginTop: "15px" }}>
            <ProfileBar />
          </div>
          <div className="task_AmountSection">
            <img src={creditCoinGif} alt="" height="60px" width="60px" />
            <span className="task_amount">
              {formatNumberWithCommas(
                userCredits +
                  Math.floor(clubCredits) +
                  Math.floor(motorCredits) +
                  Math.floor(hotelCredits)
              )}
            </span>
          </div>
        </animated.div>

        <div
          style={{
            width: "90%",
            height: "180px",
            border: "1px solid #FAE469",
            borderRadius: "15px",
            margin: "10px auto",
          }}
        >
          {/* <img
            src={statsImg}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              borderRadius: "20px",
            }}
            alt=""
          /> */}
        </div>
        {activeTab === "weekly" && (
          <div className={styles.yourRankBox}>
            <div className={styles.yourCreditsBox}>
              <div className={styles.yourRank}>COMPETITION END IN:</div>
              <div
                className={styles.yourCreditCount}
                style={{ fontSize: "1rem" }}
              >
                {String(timer.days).padStart(2, "0")}:
                {String(timer.hours).padStart(2, "0")}:
                {String(timer.minutes).padStart(2, "0")}:
                {String(timer.seconds).padStart(2, "0")}
              </div>
            </div>
          </div>
        )}

        <div className={styles.toggleBox}>
          {/* write toggle box code here */}
          <div className={styles.toggleButtons}>
            <button
              className={`${styles.toggleButton} ${
                activeTab === "weekly" ? styles.active : ""
              }`}
              onClick={() => handleTabClick("weekly")}
            >
              Weekly
            </button>
            <button
              className={`${styles.toggleButton} ${
                activeTab === "season" ? styles.active : ""
              }`}
              onClick={() => {
                handleTabClick("season");
              }}
            >
              Season
            </button>
          </div>
          <div className={styles.statsList}>
            {loading ? ( // Conditional rendering based on loading state
              <div
                className={styles.loaderContainer}
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Loader}
                  alt="Loading..."
                  className={styles.loader}
                  style={{ margin: "auto", width: "5rem" }}
                />
              </div>
            ) : (
              (activeTab === "weekly" ? weeklyRanking : allTimeRanking).map(
                (
                  {
                    name,
                    credits,
                    rank,
                    kycCompleted,
                    clubRank,
                    telegramUsername,
                  },
                  index
                ) => (
                  <div key={index} className={styles.statsItem}>
                    {/* Rest of your code remains unchanged */}
                    <div className={styles.ppl}>
                      {index === 0 && (
                        <img
                          src={rank1Gif}
                          alt="ppLevel"
                          style={{
                            objectFit: "cover",
                            width: "75px",
                            height: "75px",
                            marginLeft: "-25px",
                          }}
                        />
                      )}
                      {index === 1 && (
                        <img
                          src={rank2Gif}
                          alt="ppLevel"
                          style={{
                            objectFit: "cover",
                            width: "75px",
                            height: "75px",
                            marginLeft: "-25px",
                          }}
                        />
                      )}
                      {index === 2 && (
                        <img
                          src={rank3Gif}
                          className={styles.ppLevel}
                          alt="ppLevel"
                          style={{
                            objectFit: "cover",
                            width: "75px",
                            height: "75px",
                            marginLeft: "-25px",
                          }}
                        />
                      )}
                      {index > 2 && (
                        <div
                          style={{
                            width: "75px",
                            height: "75px",
                            marginLeft: "-25px",
                            color: "#c9c9c9",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {index + 1}
                        </div>
                      )}

                      <img
                        src={profileIcon}
                        className={styles.ppLevel}
                        alt="ppLevel"
                      />
                    </div>
                    <div className={styles.userName}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "10px", letterSpacing: "1px" }}
                          >
                            {truncateName(name || telegramUsername || "User")}
                          </span>{" "}
                          {kycCompleted && (
                            <img
                              src={bluecheck}
                              className={styles.checkIcon}
                              alt=""
                            />
                          )}
                        </div>
                        <span
                          style={{
                            fontSize: "10px",
                            letterSpacing: "1px",
                            marginTop: "-1px",
                          }}
                        >
                          {userClubToRankNameMapping[clubRank]}
                        </span>
                      </div>

                      <br />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        width: "120px",
                        textAlign: "left",
                      }}
                    >
                      <img
                        src={v3_GoldCoin}
                        alt="coin"
                        className={styles.userIcon}
                      />
                      <div className={styles.userScore}>
                        <div className={styles.userScoreCount}>
                          {getReadableNumber(credits)}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
            )}
          </div>
        </div>

        <div className={styles.bottomNav}>
          <BottomButtons page={page} setPages={setPages} />
        </div>
        <WalletModal
          connectedAddress={connectedAddress}
          showConnectWallet={showConnectWallet}
          setShowConnectWallet={setShowConnectWallet}
        />
      </div>
    </div>
  );
};

export default Stats;
