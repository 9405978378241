import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ClubContext } from "../../../context/ClubContext";
import { HotelContext } from "../../../context/HotelContext";
import { MotorContext } from "../../../context/MotorContext";
import { UserContext } from "../../../context/UserContext";
import tapCreditImg from "../../../media/tap_credits.png";
import pepeCreditImg from "../../../media/v3_airDrop_pepeCredits.png";
import airDropStep1 from "../../../media/v3_airDrop_step1.png";
import { formatNumberWithCommas } from "../../../utils/formatedNumberWithComas";
import "./airDrop.css";
import { AuthContext } from "../../../context/AuthContext";

const AirDropMain = () => {
  const navigate = useNavigate();
  const { user, userCredits } = useContext(UserContext);
  const { motorCredits } = useContext(MotorContext);
  const { hotelCredits } = useContext(HotelContext);
  const { clubCredits } = useContext(ClubContext);
  const { jwt } = useContext(AuthContext);
  useEffect(() => {
    if (!jwt) {
      navigate("/");
    }
  }, [jwt]);
  return (
    <div className="airDropMainContainer">
      <div
        style={{
          position: "absolute",
          height: "130px",
          width: "100%",
          background:
            "linear-gradient(to top, #1D1C1D 20%, rgba(0, 0, 0, 0) 100%)",
          zIndex: 1,
          top: "32%",
          pointerEvents: "none",
        }}
      ></div>
      <div className="airDropImg_section">
        <div className="airDropCloseIcon">
          <span onClick={() => navigate("/?noload=true")}>CLOSE X</span>
        </div>
        <img src={airDropStep1} alt="" className="airDropTopImg" />
      </div>

      <div
        className="airDrop_Text_section"
        style={{
          zIndex: 101010,
          position: "absolute",
        }}
      >
        <div style={{}}>
          <span
            style={{
              position: "absolute",
              fontSize: "25px",
              color: "black",
              background: "white",
              textAlign: "center",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: "-35%",
              right: "3%",
              zIndex: 101010,
              border: "0.5px solid black",
            }}
          >
            ?
          </span>
          <button
            style={{
              background: "white",
              color: "black",
              fontWeight: "700",
              fontSize: "18px",
              padding: "5px 10px",
              outline: "none",
              border: "none",
              borderRadius: "20px",
              textAlign: "center",
              width: "230px",
            }}
          >
            YOUR AIRDROP
          </button>
        </div>
      </div>
      <div
        style={{
          margin: "10px auto",
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: 600,
          position: "absolute",
          zIndex: 1,
          marginTop: "2rem",
        }}
      >
        <span>
          Your Pepe’s Frogbar activity earned you an Airdrop, connect wallet to
          claim it!
        </span>
      </div>
      <div
        className="airDrop_Card_section"
        style={{
          marginTop: "6rem",
        }}
      >
        <div
          style={{
            background: "white",
            margin: "5px",
            width: "95%",
            height: "100px",
            borderRadius: "12px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "40%",
                height: "6.3rem",
                background: "#36A17F",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <img
                src={tapCreditImg}
                alt=""
                style={{
                  height: "100px",
                  width: "90%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                width: "60%",
                height: "6.3rem",
                paddingLeft: "5px",
                paddingTop: "5px",
              }}
            >
              <span
                style={{
                  fontFamily: "roboto",
                  fontWeight: 700,
                  color: "black",
                }}
              >
                TAP CREDITS
              </span>

              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <span
                  style={{
                    fontFamily: "roboto",
                    fontWeight: 700,
                    fontSize: "22px",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  {formatNumberWithCommas(
                    userCredits +
                      Math.floor(clubCredits) +
                      Math.floor(motorCredits) +
                      Math.floor(hotelCredits)
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: "white",
            margin: "5px",
            width: "95%",
            height: "100px",
            borderRadius: "12px",
            marginTop: "1rem",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "40%",
                height: "6.3rem",
                background: "#CF00F4FC",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <img
                src={pepeCreditImg}
                alt=""
                style={{
                  height: "100px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                width: "60%",
                height: "6.3rem",
                paddingLeft: "5px",
                paddingTop: "5px",
              }}
            >
              <span
                style={{
                  fontFamily: "roboto",
                  fontWeight: 700,
                  color: "black",
                }}
              >
                PEPE CREDITS
              </span>

              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <span
                  style={{
                    fontFamily: "roboto",
                    fontWeight: 700,
                    fontSize: "22px",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  {user?.pepeCredits?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: "10px",
        }}
      >
        <button
          className="stripe-button"
          style={{ marginTop: "1rem" }}
          onClick={() => {
            if (navigator.vibrate) {
              navigator.vibrate(50);
            } else if (window.Telegram?.WebApp) {
              window.Telegram?.WebApp.HapticFeedback.notificationOccurred(
                "success"
              );
            }
            navigate("/airdrop/step3");
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default AirDropMain;
