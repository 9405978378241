import { DateTime } from "luxon";
import React from "react";
import Countdown from "react-countdown";
import { BiLogoTelegram } from "react-icons/bi";
import { FaYoutube } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import pepeCreditsImg from "../../../media/newPEPE.png";
import tapCreditImg from "../../../media/tap_credits.png";
import v3XImg from "../../../media/v3_xLogo.png";
import { formatNumberWithCommas } from "../../../utils/formatedNumberWithComas";
import { getMobileOperatingSystem } from "../../../utils/helpers";
import "./countdown.css";
const FrogbarOverComponent = ({
  setShowAirDropEnds,
  userTapCredits,
  solanaWalletAddress,
  pepeCredits,
}) => {
  const currentTimeInDubai = DateTime.now().setZone("Asia/Dubai");
  let targetTimeInDubai = currentTimeInDubai.set({
    hour: 22,
    minute: 0,
    second: 0,
  });
  if (currentTimeInDubai > targetTimeInDubai) {
    targetTimeInDubai = targetTimeInDubai.plus({ days: 1 });
  }
  const targetDate = targetTimeInDubai.toJSDate();

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className="count_down">
          <div className="count_box">
            <div className="count">0</div>
            <span className="count_title">HOUR</span>
          </div>
          <div className="count_box">
            <div className="count">0</div>
            <span className="count_title">MIN</span>
          </div>
          <div className="count_box">
            <div className="count">0</div>
            <span className="count_title">SEC</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="count_down">
          <div className="count_box">
            <div className="count">{hours}</div>
            <span className="count_title">HOUR</span>
          </div>
          <div className="count_box">
            <div className="count">{minutes}</div>
            <span className="count_title">MIN</span>
          </div>
          <div className="count_box">
            <div className="count">{seconds}</div>
            <span className="count_title">SEC</span>
          </div>
        </div>
      );
    }
  };

  const navigate = useNavigate();
  const openUrl = (url) => {
    if (getMobileOperatingSystem() === "iOS") {
      window.location.href = url;
    } else {
      window.open(url);
    }
  };
  const userPepeCredits = pepeCredits.toFixed(3);
  return (
    <div className="frogbar_over_container">
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <button
          style={{
            backgroundColor: "#4CAF50",
            outline: "none",
            border: "none",
            padding: "10px 20px",
            borderRadius: "8px",
            color: "white",
          }}
          onClick={() => setShowAirDropEnds(false)}
        >
          close
        </button>
      </div> */}
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            color: "white",
            textTransform: "uppercase",
            fontFamily: "Roboto",
            fontWeight: 600,
          }}
        >
          Season one of
        </span>
        <span
          style={{
            fontSize: "40px",
            color: "white",
            textTransform: "uppercase",
            fontFamily: "Roboto",
            fontWeight: 600,
          }}
        >
          PEPE'S FROGBAR
        </span>
        <span
          style={{
            fontSize: "18px",
            color: "white",
            textTransform: "uppercase",
            fontFamily: "Roboto",
            fontWeight: 600,
            margin: "5px auto",
          }}
        >
          is officially over
        </span>
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              color: "white",
              textTransform: "uppercase",
              fontFamily: "Roboto",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            YOUR ACCOMPLISHMENTS WITH <br /> PEPE’S FROGBAR P2E SO FAR:
          </span>
        </div>
      </div>
      <div
        style={{
          margin: "1.5rem 15px 5px 5px",
        }}
      >
        {/* card 1 */}
        <div
          style={{
            background: "white",
            margin: "5px",
            width: "100%",
            height: "100px",
            borderRadius: "12px",
            marginBottom: "1rem",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "40%",
                height: "100px",
                background: "#62BB09",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={tapCreditImg}
                alt=""
                style={{
                  height: "85px",
                  width: "85px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                width: "60%",
                height: "6.3rem",
                paddingLeft: "10px",
                paddingTop: "5px",
              }}
            >
              <span
                style={{
                  fontFamily: "roboto",
                  fontWeight: 700,
                  color: "black",
                }}
              >
                TAP
              </span>
              <br />
              <span
                style={{
                  fontFamily: "roboto",
                  fontWeight: 800,
                  color: "black",
                  fontSize: "25px",
                  letterSpacing: "1.5px",
                }}
              >
                CREDITS
              </span>

              <div>
                <span
                  style={{
                    fontFamily: "roboto",
                    fontWeight: 700,
                    fontSize: "22px",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  {formatNumberWithCommas(userTapCredits)}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* card 2 */}
        <div
          style={{
            background: "white",
            margin: "5px",
            width: "100%",
            height: "100px",
            borderRadius: "12px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "40%",
                height: "100px",
                background: "blue",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={pepeCreditsImg}
                alt=""
                style={{
                  height: "90px",
                  width: "90px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                width: "60%",
                height: "6.3rem",
                paddingLeft: "10px",
                paddingTop: "5px",
              }}
            >
              <span
                style={{
                  fontFamily: "roboto",
                  fontWeight: 700,
                  color: "black",
                }}
              >
                PEPE
              </span>
              <br />
              <span
                style={{
                  fontFamily: "roboto",
                  fontWeight: 800,
                  color: "black",
                  fontSize: "25px",
                  letterSpacing: "1.5px",
                }}
              >
                CREDITS
              </span>

              <div
                style={{
                  marginTop: "5px",
                }}
              >
                <span
                  style={{
                    fontFamily: "roboto",
                    fontWeight: 700,
                    fontSize: "22px",
                    color: "black",
                  }}
                >
                  {formatNumberWithCommas(userPepeCredits)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          margin: "20px auto",
        }}
      >
        <span
          style={{
            fontSize: "15px",
            color: "white",
            textTransform: "uppercase",
            fontFamily: "Roboto",
            fontWeight: 400,
            textAlign: "center",
          }}
        >
          Your Airdrop will be delivered directly to the Solana Wallet you
          provided
        </span>
        <div
          style={{
            background: "white",
            color: "blue",
            fontFamily: "Roboto",
            textAlign: "center",
            width: "90%",
            padding: "10px",
            borderRadius: "10px",
            fontWeight: 800,
            marginTop: "10px",
            overflowWrap: "break-word",
          }}
        >
          <span
            style={{
              width: "100vw",
              overflowX: "hidden",
              fontSize: "12px",
              wordBreak: "break-word",
            }}
          >
            {solanaWalletAddress}
          </span>
        </div>
        <div
          style={{
            marginTop: "15px",
          }}
        >
          <span>AIRDROP IN :</span>
          {targetDate ? (
            <Countdown date={targetDate} renderer={renderer} />
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div
          style={{
            textAlign: "center",
            padding: "10px",
            marginTop: "15px",
          }}
        >
          <span>
            Disclaimer: Due to the high volume of users participating in the
            airdrop, delivery ties are subjected to the transaction speed of the
            Solana Blockchain. While there might be delays, rest assured your
            airdrop will arrive
          </span>
        </div>
      </div>
      <div>
        <div
          style={{
            marginLeft: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            href="https://www.youtube.com/@frogbar"
            onClick={(e) => {
              e.preventDefault();
              openUrl("https://www.youtube.com/@frogbar");
            }}
            style={{
              background: "rgba(0, 0, 0, 1)",
              height: "40px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              border: "2px solid #BFA75D",
              margin: "0 5px",
            }}
          >
            <FaYoutube color="#FF0000" fontSize="25px" />
          </a>
          <a
            href="https://x.com/pepefrogbar" // Link for the other icon
            onClick={(e) => {
              e.preventDefault();
              openUrl("https://x.com/pepefrogbar");
            }}
            style={{
              background: "rgba(0, 0, 0, 1)",
              height: "40px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              border: "2px solid #BFA75D",
              margin: "0 5px",
            }}
          >
            <img src={v3XImg} alt="x" height="40px" width="40px" />
          </a>
          <a
            href="https://t.me/pepefrogbar" // Telegram link
            onClick={(e) => {
              e.preventDefault();
              openUrl("https://t.me/pepefrogbar");
            }}
            style={{
              background: "rgba(0, 0, 0, 1)",
              height: "40px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              border: "2px solid #BFA75D",
              margin: "0 5px",
            }}
          >
            <BiLogoTelegram color="#229ED9" fontSize="30px" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FrogbarOverComponent;
