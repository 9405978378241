import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { UserContext } from "../../../context/UserContext";
import v3_bornzeCoin from "../../../media/v3_bronze.png";
import v3_frogCoin from "../../../media/v3_frog.png";
import v3_goldCoin from "../../../media/v3_gold.png";
import gameBg from "../../../media/v3_minigame_scratcCoin.png";
import v3_silverCoin from "../../../media/v3_silver.png";
import useTelegramBackButton from "../../../utils/backButton";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import "./minigame-2.css";

const prizes = ["bronze", "silver", "gold", "frog"];
const val = {
  bronze: 100000,
  silver: 250000,
  gold: 500000,
  frog: 2000000,
};
const valk = {
  bronze: "100k",
  silver: "250k",
  gold: "500k",
  frog: "2M",
};
const image = {
  bronze: v3_bornzeCoin,
  silver: v3_silverCoin,
  gold: v3_goldCoin,
  frog: v3_frogCoin,
};

const MiniGame2 = ({
  isOpen,
  onClose,
  handleGamePopup,
  show,
  setShow,
  setMiniGameResult,
  congo,
  setUpdatedCredits,
}) => {
  useTelegramBackButton();
  const [result, setResult] = useState("");
  const [boxes, setBoxes] = useState([]);
  const [isClicked, setIsClicked] = useState(Array(12).fill(false));
  const [countScratch, setCountScratch] = useState(0);

  const { jwt } = useContext(AuthContext);
  const [isCreditsLoaded, setIsCreditsLoaded] = useState(false);
  const [displayedCredits, setDisplayedCredits] = useState(0);
  const { userCredits, setUserCredits } = useContext(UserContext);
  const hasRun = useRef(false);
  const navigate = useNavigate();
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const popupRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [token, setToken] = useState("");
  const [tickets, setTickets] = useState(0);
  const [playAvailable, setPlayAvailable] = useState(false);
  const [resRecieved, setResRecieved] = useState(false);
  const [remTime, setRemTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(remTime);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setShowPopup(true), 50);
    } else {
      setShowPopup(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handlePageLoad = () => {
      const hasLoaded = sessionStorage.getItem("hasLoaded");
      if (hasLoaded) {
        navigate("/");
      } else {
        sessionStorage.setItem("hasLoaded", "true");
      }
    };
    handlePageLoad();
    return () => {
      sessionStorage.removeItem("hasLoaded");
    };
  }, [navigate]);

  const incrementCredits = (newCredits) => {
    const difference = newCredits - userCredits;
    let currentCredits = userCredits;
    const increment = Math.ceil(difference / 50);

    const interval = setInterval(() => {
      currentCredits += increment;
      if (currentCredits >= newCredits) {
        currentCredits = newCredits;
        clearInterval(interval);
      }
      setDisplayedCredits(currentCredits);
    }, 30);
  };

  const handleClaim = (newCredits) => {
    setUserCredits(newCredits); // Update the user's credits
    incrementCredits(newCredits); // Animate the increment
    setShow(false);
    setUserCredits(newCredits);
    incrementCredits(newCredits);
  };

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const debounceClick = (func, delay) => {
    let timeout;
    return (...args) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const cardClicked = debounceClick((value, index) => {
    if (navigator.vibrate) {
      navigator.vibrate(50);
    } else if (window.Telegram?.WebApp) {
      window.Telegram?.WebApp.HapticFeedback.notificationOccurred("success");
    }
    if (isClicked[index]) return;
    if (value === result) {
      if (countScratch + 1 === 3) {
        setShow(true);
        handleGamePopup();
        async function addCredits() {
          try {
            const response = await fetch(
              `${SERVER_ENDPOINT}/v1/puzzle/addCredits`,
              {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${jwt}`,
                },
                body: JSON.stringify({
                  tap: result,
                  token,
                }),
              }
            );
            const parsedResponse = await response.json();

            if (parsedResponse.status === "SUCCESS") {
              setUpdatedCredits(parsedResponse?.data?.credits);
              setIsCreditsLoaded(true);
            }
          } catch (error) {
            console.error(error);
          }
        }
        addCredits();
      }
      setCountScratch(countScratch + 1);
    }
    const newClickedStates = [...isClicked];
    newClickedStates[index] = true;
    setIsClicked(newClickedStates);
  }, 300);

  useEffect(() => {
    setDisplayedCredits(userCredits);
  }, [userCredits]);

  useEffect(() => {
    const preloadImages = () => {
      const imageUrls = Object.values(image);
      let loadedImages = 0;
      const totalImages = imageUrls.length;
      const onImageLoad = () => {
        loadedImages += 1;
        if (loadedImages === totalImages) {
          setImagesLoaded(true);
        }
      };

      imageUrls.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = onImageLoad;
      });
    };

    preloadImages();
  }, []);

  useEffect(() => {
    async function fetchResult() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/puzzle/claimNewOutcome`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        const parsedResponse = await response.json();
        setResult(parsedResponse?.data);
        setMiniGameResult(parsedResponse?.data);
        setToken(parsedResponse?.token);
        const index = prizes.indexOf(parsedResponse?.data);
        let array = [];
        for (let i = 0; i < 12; i++) {
          if (i < 6) array.push(prizes[index]);
          else if (i < 8) array.push(prizes[(index + 1) % 4]);
          else if (i < 10) array.push(prizes[(index + 2) % 4]);
          else array.push(prizes[(index + 3) % 4]);
        }
        setBoxes(shuffle(array));
      } catch (error) {
        console.error(error);
      }
    }
    if (!hasRun.current) {
      hasRun.current = true;
      fetchResult();
    }
  }, [jwt]);
  useEffect(() => {
    async function isAvailable() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/puzzle/isAvailable`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        const parsedResponse = await response.json();

        if (parsedResponse.status == "SUCCESS") {
          setPlayAvailable(true);
          setResRecieved(true);
          setTickets(parsedResponse.data.playCount);
          setRemTime(0);
        }
        if (parsedResponse.status == "FAILED") {
          setResRecieved(true);
          setPlayAvailable(false);
          setTickets(
            parsedResponse.error.playCount >= 3
              ? 3
              : parsedResponse.error.playCount
          );
          if (parsedResponse.error.playCount >= 3) {
            setRemTime(parsedResponse.error.lastPlayTime);
          } else {
            setRemTime(60 * 30 - parsedResponse.error.lastPlayTime);
            // setRemTime(60 - parsedResponse.error.lastPlayTime);
          }
        }
      } catch (error) {
        setPlayAvailable(false);
        console.log(error);
      }
    }

    isAvailable();
  }, []);
  // useEffect(() => {
  //   if (show) {
  //     handleGamePopup();
  //     setShow(true);
  //   }
  // }, [show, handleGamePopup]);

  return (
    <div>
      {imagesLoaded ? (
        <>
          <div className="mini_game_content">
            <span className="title_minigame">SCRATCH TO WIN</span> <br />
            <span className="subTitle_minigame">Win up to 2,000,000</span>
          </div>

          <div className="numberBoxMain">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontStyle: "italic",
                fontWeight: "bold",
                color: "gray",
                margin: "15px",
              }}
            >
              <span>Tickets left</span>
              <span>{3 - tickets} of 3</span>
            </div>
            <div className="outsideBox">
              <div className="numberBox_2">
                <div className="onlyBorder">
                  <div className="NumberContentBox">
                    <div className="mainCoinBox">
                      {boxes.length > 0 &&
                        boxes.map((value, index) => (
                          <div
                            key={index}
                            className="box"
                            onClick={() => cardClicked(value, index)}
                          >
                            <img
                              className="boxImg"
                              src={isClicked[index] ? image[value] : gameBg}
                              alt="game"
                              style={{
                                transform: isClicked[index]
                                  ? "scale(0.9)"
                                  : "scale(1)",
                                transition: "transform 0.3s ease-in-out",
                                borderRadius: "5px",
                                objectFit: isClicked[index]
                                  ? "contain"
                                  : "unset",
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_of_miniGame">
            <span className="bottomTitle">Rules:</span>
            <br />
            <span className="bottomSubtitle">
              Scratch 3 of each and win following prizes:
            </span>
            <div className="winningBottom">
              {Object.entries(image).map(([key, src]) => (
                <div key={key} style={{ textAlign: "center" }}>
                  <img
                    src={src}
                    alt={`${key} coin`}
                    width="50px"
                    height="50px"
                    style={{
                      objectFit: "cover",
                    }}
                  />

                  <br />
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "25px",
                      color: "gold",
                    }}
                  >
                    {valk[key].toLocaleString()}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="loader">Loading...</div>
      )}
    </div>
  );
};

export default MiniGame2;
