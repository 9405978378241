import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { ClubContext } from "../../../context/ClubContext";
import { HotelContext } from "../../../context/HotelContext";
import { MotorContext } from "../../../context/MotorContext";
import MoneyExchangeVideo from "../../../media/Bitcoin_Exchange.gif";
import v3_ClubButton from "../../../media/v3_clubButton.png";
import v3_PlayButton from "../../../media/v3_playButton.png";
import v3_ProfileIcon from "../../../media/v3_profileIcon.png";
import v3_taskIconButton from "../../../media/v3_taskButtonIcon.png";
import { handleVibrateOnOnclick } from "../../../utils/Vibrate";
import "./style.css";

const BottomButtons = () => {
  const { pathname } = useLocation();
  const page = pathname.split("/")[1];
  const { taskPending, setTaskPending } = useContext(AuthContext);
  const { fetchUserClub } = useContext(ClubContext);
  const { fetchUserMotors } = useContext(MotorContext);
  const { fetchUserHotel } = useContext(HotelContext);
  const navigate = useNavigate();

  const [isPopupOpen, setPopupOpen] = useState(false);

  const handlePartnerClick = () => {
    setPopupOpen(true); // Open the popup
  };

  const handleClosePopup = () => {
    setPopupOpen(false); // Close the popup
  };

  useEffect(() => {
    if (page === "task") setTaskPending(false);
  }, [page, setTaskPending]);
  const handleVibrate = () => {
    if (navigator.vibrate) {
      navigator.vibrate(50);
    } else if (window.Telegram?.WebApp) {
      window.Telegram?.WebApp.HapticFeedback.notificationOccurred("success");
    }
  };

  const handleClubClick = () => {
    handleVibrateOnOnclick();
    console.log("called");
    fetchUserClub();
    fetchUserHotel();
    fetchUserMotors();
  };
  // Conditionally render Profile popup or BottomButtons
  return (
    <>
      <div>
        <div className="bottomButtons">
          <div className="navButtons">
            <NavLink
              to="/?noload=true"
              style={
                ({ textDecoration: "none" },
                ({ isActive }) => ({
                  background: `${isActive ? "#212429" : ""}`,
                  textDecoration: "none",
                  borderRadius: "5px",
                  padding: "5px",
                }))
              }
            >
              <div>
                <div
                  className="buttonChildDiv"
                  style={{ width: "100%", textAlign: "center" }}
                  onClick={() => handleVibrateOnOnclick()}
                >
                  <img
                    src={v3_PlayButton}
                    className="buttonIcon"
                    alt="Play Button"
                  />
                  <span className="buttonText">Play</span>
                </div>
              </div>
            </NavLink>
            <NavLink
              to="/task"
              style={
                ({ textDecoration: "none" },
                ({ isActive }) => ({
                  background: `${isActive ? "#212429" : ""}`,
                  textDecoration: "none",
                  borderRadius: "5px",
                  padding: "5px",
                }))
              }
            >
              <div className=" second">
                <div
                  onClick={() => handleVibrateOnOnclick()}
                  className="buttonChildDiv"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ display: taskPending ? "block" : "none" }}
                    className="red"
                  >
                    {taskPending}
                  </div>
                  <img
                    src={v3_taskIconButton}
                    className="buttonIcon"
                    alt="Task Button"
                  />
                  <span className="buttonText">Task</span>
                </div>
              </div>
            </NavLink>
            <div className="">
              <Link to="/airdrop" style={{ textDecoration: "none" }}>
                <div onClick={() => handleVibrateOnOnclick()}>
                  <img
                    src={MoneyExchangeVideo}
                    alt="Privet Sale Animation"
                    height="80px"
                    width="80px"
                    style={{
                      objectFit: "contain",
                      position: "relative",
                      zIndex: "2",
                    }}
                  />
                </div>
              </Link>
            </div>

            <NavLink
              to="/club"
              style={
                ({ textDecoration: "none" },
                ({ isActive }) => ({
                  background: `${isActive ? "#212429" : ""}`,
                  textDecoration: "none",
                  borderRadius: "5px",
                  padding: "5px",
                }))
              }
            >
              <div className="">
                <div
                  onClick={() => handleClubClick()}
                  className="buttonChildDiv"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <img
                    src={v3_ClubButton}
                    className="buttonIcon"
                    alt="Club Button"
                  />
                  <span className="buttonText">Club</span>
                </div>
              </div>
            </NavLink>
            <div>
              <div
                onClick={() => handleVibrateOnOnclick()}
                className="buttonChildDiv"
                style={{
                  width: "100%",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                // onClick={handlePartnerClick} // Add onClick handler
              >
                <NavLink
                  to="/friend"
                  style={
                    ({ textDecoration: "none" },
                    ({ isActive }) => ({
                      background: `${isActive ? "#212429" : ""}`,
                      textDecoration: "none",
                      borderRadius: "5px",
                      padding: "5px",
                    }))
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={v3_ProfileIcon}
                      className="buttonIcon"
                      alt="Profile Button"
                    />
                    <span className="buttonText">friends</span>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomButtons;
