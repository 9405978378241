import { animated, useSpring } from "@react-spring/web";
import React, { useContext, useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import { IoCloseCircle } from "react-icons/io5";
import { UserContext } from "../../context/UserContext";
import "./announcement.css";

const AnnouncementModal = ({
  isAnnouncement,
  setIsAnnouncement,
  newAnnouncement,
  blink,
}) => {
  const modalRef = useRef();
  const [visible, setVisible] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { user, setShowReturnPopup } = useContext(UserContext);

  const profileBoxAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0.1 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });

      await next({ backdropFilter: "blur(40px)" });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(0%)",
      });
    },
    config: { duration: 400 },
  });

  const buyBoxAnimation = useSpring({
    from: { transform: "translateY(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(0%)",
      });
    },
    config: { duration: 400 },
  });

  useEffect(() => {
    setShowConfetti(true);
    setShowReturnPopup(false);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setIsAnnouncement(false);
      }
    };

    if (isAnnouncement) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isAnnouncement]);

  // useEffect(() => {
  //   setVisible(false);
  // }, []);

  return (
    <div
      className="modal"
      style={{
        display: isAnnouncement ? "flex" : "none",
        justifyContent: "flex-end",
      }}
    >
      {showConfetti && blink && (
        <div className="confetti">
          {" "}
          <Confetti width={820} recycle={false} />
        </div>
      )}
      <animated.div style={{ ...buyBoxAnimation }}>
        <div className="modal-content" ref={modalRef}>
          <div
            onClick={() => {
              setIsAnnouncement(false);
              setShowConfetti(false);
            }}
            style={{
              position: "absolute",
              right: 10,
              marginRight: "12px",
              top: 10,
              color: "white",
            }}
          >
            <IoCloseCircle className="closeIcon_ax" />
          </div>
          <div className="announcementContainer">
            <div>
              <div className="announcementBox">
                {newAnnouncement ? (
                  <>
                    <img
                      src={newAnnouncement?.image}
                      alt="announcement"
                      className="announcementImage"
                    />
                    <div className="announcementTitle">
                      {newAnnouncement?.name}
                    </div>
                    <div className="announcementContent">
                      {newAnnouncement?.desc}
                    </div>
                  </>
                ) : (
                  <div className="announcementTitle">No New Announcement</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default AnnouncementModal;
