import { OKXSolanaProvider } from "@okxconnect/solana-provider";
import { OKXTonConnectUI, OKXUniversalConnectUI, THEME } from "@okxconnect/ui";
import { toUserFriendlyAddress } from "@tonconnect/sdk";
import { useTonConnectUI } from "@tonconnect/ui-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { SERVER_ENDPOINT } from "../utils/constants";
import { AuthContext } from "./AuthContext";
import { UserContext } from "./UserContext";

export const WalletContext = createContext();

export default function WalletContextProvider({ children }) {
  const [okxUI, setOkxUI] = useState();
  const [okxSolanaUI, setOkxSolanaUI] = useState();
  const [tonConnectUI] = useTonConnectUI();
  const [activeWallet, setActiveWallet] = useState(null);
  const [activeWalletAddress, setActiveWalletAddress] = useState(null);
  const { user } = useContext(UserContext);
  const { jwt } = useContext(AuthContext);

  useEffect(() => {
    async function initOkx() {
      if (!customElements.get("okxc-root")) {
        const ui = new OKXTonConnectUI({
          dappMetaData: {
            name: "PepeFrogbar",
          },
          actionsConfiguration: {
            returnStrategy: "none",
            tmaReturnUrl: "back",
          },
          uiPreferences: {
            theme: THEME.LIGHT,
          },
          language: "en_US",
          restoreConnection: true,
        });
        setOkxUI(ui);
      }
    }

    async function initOkxSolana() {
      const universalUi = await OKXUniversalConnectUI.init({
        dappMetaData: {
          name: "PepeFrogbar",
        },
        actionsConfiguration: {
          returnStrategy: "tg://resolve",
          modals: "all",
          tmaReturnUrl: "back",
        },
        language: "en_US",
      });
      setOkxSolanaUI(universalUi);

      if (universalUi?.connected()) {
        try {
          let provider = new OKXSolanaProvider(universalUi);
          setActiveWalletAddress(provider.getAccount().address);
          await saveSolanaAddresstobackend(provider.getAccount().address);
        } catch (error) {
          // alert("error occured");
          console.log(JSON.stringify(error));
        }
      }
    }
    initOkx();
    initOkxSolana();
  }, [jwt]);

  const saveSolanaAddresstobackend = async (address) => {
    if (jwt?.length < 1) return;
    // if (user?.solanaWalletAddress.length > 6) return;
    // if the wallet is already there, it will not be saved again
    await fetch(SERVER_ENDPOINT + "/v1/user/addSolanaAddress", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        userID: user.userID,
        solanaWalletAddress: address,
      }),
    });
  };

  useEffect(() => {
    const connectWallets = async () => {
      if (tonConnectUI) {
        tonConnectUI.onStatusChange((connectedWallet) => {
          if (connectedWallet) {
            setActiveWallet("ton");

            setActiveWalletAddress(
              toUserFriendlyAddress(connectedWallet.account.address)
            );
          }
        });
      }
      if (okxUI) {
        okxUI.onStatusChange((connectedWallet) => {
          if (connectedWallet) {
            setActiveWallet("okx");
            setActiveWalletAddress(
              toUserFriendlyAddress(connectedWallet.account.address)
            );
          }
        });
      }
      if (okxSolanaUI?.connected()) {
        try {
          let provider = new OKXSolanaProvider(okxSolanaUI);
          // solanaWalletAddress
          if (user?.solanaWalletAddress.length > 6) {
            // dummy length of 6, later change to actual wallet length
            setActiveWalletAddress(provider.getAccount().address);
          } else {
            if (provider.getAccount().address) {
              await saveSolanaAddresstobackend(provider.getAccount().address);
            }
          }
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      }
    };
    connectWallets();
  }, [jwt, okxSolanaUI, okxUI, tonConnectUI, user]);

  async function sendActiveWalletTransaction(txnObject) {
    if (activeWallet === "okx") {
      await okxUI.sendTransaction(txnObject);
    }
    if (activeWallet === "ton") {
      await tonConnectUI.sendTransaction(txnObject);
    }
  }

  async function handleOkxConnect() {
    await okxUI.openModal();
  }
  async function handleTonConnect() {
    await tonConnectUI.openModal();
  }
  async function handleOkxSolanaConnect() {
    await okxSolanaUI.openModal({
      namespaces: {
        solana: {
          chains: [
            "solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp", // solana mainnet
            //  "solana:4uhcVJyU9pJkvQyS88uRDiswHXSCkY3z",// solana testnet
            //  "sonic:4uhcVJyU9pJkvQyS88uRDiswHXSCkY3z",// sonic testnet ；
          ],
        },
      },
    });
    let provider = new OKXSolanaProvider(okxSolanaUI);
    const address = await provider.getAccount();
    setActiveWalletAddress(address.address);
    return address.address;
  }
  async function handleActiveWalletDisconnect() {
    // if (activeWallet === "okx") await okxUI.disconnect()
    // if (activeWallet === "ton") await tonConnectUI.disconnect();
    await okxSolanaUI.disconnect();
    setActiveWallet(null);
    setActiveWalletAddress(null);
  }

  return (
    <WalletContext.Provider
      value={{
        okxUI,
        okxSolanaUI,
        handleOkxSolanaConnect,
        activeWallet,
        activeWalletAddress,
        sendActiveWalletTransaction,
        handleOkxConnect,
        handleTonConnect,
        handleActiveWalletDisconnect,
        saveSolanaAddresstobackend,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
}
