import { animated, useSpring } from "@react-spring/web";
import { useTonAddress } from "@tonconnect/ui-react";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ClubContext } from "../../../context/ClubContext";
import { HotelContext } from "../../../context/HotelContext";
import { MotorContext } from "../../../context/MotorContext";
import { UserContext } from "../../../context/UserContext";
import creditCoinGif from "../../../media/silver-pepe-bg.png";
import v3_Arrrow from "../../../media/V3_arrow.png";
import v3_GreenTick from "../../../media/v3green-tick.png";
import useTelegramBackButton from "../../../utils/backButton";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { formatNumberWithCommas } from "../../../utils/formatedNumberWithComas";
import { getMobileOperatingSystem } from "../../../utils/helpers";
import partnersData from "../../component/Partners/newPartnersData.json";
import SocialAccounts from "../../component/Tasks-screen/SocialAccounts";
import SwitchableTaskCard from "../../component/Tasks-screen/SwitchableTaskCard";
import BottomButtons from "../../Shared/BottomNavigation/bottomButtons";
import ProfileBar from "../../Shared/ProfileBar/ProfileBar";
import V3Popup from "../../Shared/v3Popup/V3Popup";
import WalletModal from "../WalletModal";
import DemoPartner from "./demoPartner/DemoPartner";
import "./tasks.css";
const Tasks = () => {
  useTelegramBackButton();
  const [tasks, setTasks] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [page, setPages] = useState("friend");
  const [visible, setVisible] = useState(false);
  const [tab, setTab] = useState("Partners");
  const connectedAddress = useTonAddress();
  const {
    showConnectWallet,
    setShowConnectWallet,
    user,
    userCredits,
    setUserCredits,
  } = useContext(UserContext);
  const { jwt } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [screen, setScreen] = useState("");
  const [claimStatuses, setClaimStatuses] = useState({});
  const { clubCredits, userClubs, setUserClubs, userStars, userClubRank } =
    useContext(ClubContext);
  const { userMotors, setUserMotors, motorCredits } = useContext(MotorContext);
  const { userHotels, setUserHotels, hotelCredits } = useContext(HotelContext);

  const { taskPendingX, taskPendingYT } = useContext(AuthContext);
  const partnerTaskPending = Object.values(claimStatuses).filter(
    (status) => status === false
  ).length;

  const topAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(90%)",
      });
    },
    config: { duration: 300 },
  });
  useEffect(() => {
    const fetchClaimStatuses = async () => {
      const statuses = {};
      for (const partner of partnersData) {
        try {
          const res = await fetch(
            `${SERVER_ENDPOINT}/v1/partner/checkClaimStatus`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                user_id: user.userID,
                partner_id: partner.partner_id,
              }),
            }
          );
          const data = await res.json();
          statuses[partner.partner_id] = data.status;
        } catch (error) {
          statuses[partner.partner_id] = false;
        }
      }
      setClaimStatuses(statuses);
    };
    fetchClaimStatuses();
  }, []);

  const leftAnimation = useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(0%)",
      });
    },
    config: { duration: 300 },
  });

  const rightAnimation = useSpring({
    from: { transform: "translateX(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(0%)",
      });
    },
    config: { duration: 300 },
  });

  useEffect(() => {
    setPages("task");
  }, []);

  useEffect(() => {
    async function fetchTasks() {
      try {
        setIsLoading(true);
        const response = await fetch(`${SERVER_ENDPOINT}/v1/task/list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ screen }),
        });
        const parsedResponse = await response.json();
        setTasks(parsedResponse.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    if (tab == "Special") {
      fetchTasks();
    }
  }, [tab]);

  const handlePopupClose = () => {
    setPopupOpen(false);
  };
  const handlePopupOpen = (partner) => {
    setSelectedPartner(partner);
    setPopupOpen(true);
  };
  const formatReward = (reward) => {
    if (reward >= 1000000) {
      return `${Math.floor(reward / 1000000)}M`; // Convert to millions (M) without decimals
    } else if (reward >= 1000) {
      return `${Math.floor(reward / 1000)}k`; // Convert to thousands (K) without decimals
    }
    return reward; // Return the original value if it's less than 1000
  };

  const updateClaimStatus = (partnerId) => {
    setClaimStatuses((prevStatuses) => ({
      ...prevStatuses,
      [partnerId]: true, // Mark the claim as completed for the given partner
    }));
  };
  const [selectedPartner, setSelectedPartner] = useState(null);

  const handleSpecialTask = async (task) => {
    const { taskID, url } = task;
    try {
      const response = await fetch(`${SERVER_ENDPOINT}/v1/task/redeem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ taskID }),
      });
      const parsedResponse = await response.json();
      if (parsedResponse.status === "SUCCESS") {
        setTimeout(
          () =>
            setTasks((tasks) => {
              return tasks.map((task) => {
                if (task.taskID === parsedResponse.data.taskID) {
                  task.completed = true;
                }
                return task;
              });
            }),
          5000
        );
        setUserCredits(
          (credits) => credits + parseInt(parsedResponse.data.reward)
        );
      } else throw new Error("Failed to redeem task");
    } catch (error) {
      console.log(error);
    } finally {
      if (getMobileOperatingSystem() === "iOS") {
        window.location.href = url;
      } else {
        window.open(url);
      }
    }
  };

  return (
    <div>
      <div className="taskContainer">
        <div className="taskContent">
          <animated.div style={{ ...topAnimation }}>
            <div className="pfBarr2">
              <ProfileBar
                showConnectWallet={showConnectWallet}
                setShowConnectWallet={setShowConnectWallet}
                connectedAddress={connectedAddress}
              />
              <div className="task_AmountSection">
                <img src={creditCoinGif} alt="" height="60px" width="60px" />
                <span className="task_amount">
                  {formatNumberWithCommas(
                    userCredits +
                      Math.floor(clubCredits) +
                      Math.floor(motorCredits) +
                      Math.floor(hotelCredits)
                  )}
                </span>
              </div>
            </div>
          </animated.div>

          <div className="socialAccouts3rx">
            <animated.div style={{ ...leftAnimation }}>
              <SocialAccounts />
            </animated.div>
          </div>
          {/* switch section */}
          <animated.div style={{}}>
            <div
              style={{
                width: "92vw",
                background: " rgba(51, 51, 51, 0.25)",
                border: "1px solid rgba(201, 201, 201, 1)",
                marginTop: "20px",
                height: "40px",
                borderRadius: "15px",
                color: "white",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "8px",
                gap: "4px",
              }}
            >
              <div
                className={`taskSwitchTab ${
                  tab === "Partners" ? "activeSwitch" : ""
                }`}
                onClick={() => setTab("Partners")}
                style={{
                  position: "relative",
                }}
              >
                Partners
                {/* <div
                  style={{
                    // display: !taskPendingX || tab === "X" ? "none" : "block",
                    zIndex: 0,
                    position: "absolute",
                    background: "red",
                    color: "white",
                    top: "-40%",
                    right: "0%",
                    borderRadius: "50%",
                    fontSize: "10px",
                    width: "15px",
                    height: "15px",
                    textAlign: "center",
                  }}
                >
                  {partnerTaskPending}
                </div> */}
              </div>

              <div
                className={`taskSwitchTab ${
                  tab === "YouTube" ? "activeSwitch" : ""
                }`}
                onClick={() => setTab("YouTube")}
                style={{
                  position: "relative",
                }}
              >
                Youtube
                {/* <div
                  style={{
                    // display:
                    //   !taskPendingYT || tab === "YouTube" ? "none" : "block",
                    zIndex: 0,
                    position: "absolute",
                    background: "red",
                    color: "white",
                    top: "-40%",
                    right: "4%",
                    borderRadius: "50%",
                    fontSize: "10px",
                    width: "15px",
                    height: "15px",
                    textAlign: "center",
                  }}
                  className="red"
                >
                  {taskPendingYT}
                </div> */}
              </div>

              <div
                className={`taskSwitchTab ${
                  tab === "tiktok" ? "activeSwitch" : ""
                }`}
                onClick={() => setTab("tiktok")}
                style={{
                  position: "relative",
                }}
              >
                Tik Tok
                {/* <div
                  style={{
                    // display:
                    //   !taskPendingYT || tab === "YouTube" ? "none" : "block",
                    zIndex: 0,
                    position: "absolute",
                    background: "red",
                    color: "white",
                    top: "-40%",
                    right: "4%",
                    borderRadius: "50%",
                    fontSize: "10px",
                    width: "15px",
                    height: "15px",
                    textAlign: "center",
                  }}
                  className="red"
                >
                  {taskPendingYT}
                </div> */}
              </div>
            </div>
          </animated.div>
          {/* selected card */}

          {/* dummy component for pertners */}

          {tab === "Partners" && (
            <>
              {partnersData
                .sort((a, b) => {
                  const statusA = claimStatuses[a.partner_id] ? 1 : 0;
                  const statusB = claimStatuses[b.partner_id] ? 1 : 0;
                  return statusA - statusB;
                })
                .map((partner) => (
                  <div
                    key={partner.partner_id}
                    className={`task_Card borderCard ${
                      claimStatuses[partner.partner_id] ? "completed" : ""
                    }`}
                    onClick={() => handlePopupOpen(partner)}
                    style={{ width: "92vw", marginTop: "20px" }}
                  >
                    <div className="taskImg">
                      <img
                        src={partner.partner_image_url}
                        alt={partner.partner_name}
                        height="62px"
                        width="62px"
                        style={{ marginTop: "3px" }} // Keep this consistent with your static styles
                      />
                    </div>
                    <div className="task_text">
                      <span className="text_1stSpan">
                        {partner.partner_name}
                      </span>{" "}
                      <br />
                      <span
                        className="text_3rdSpan"
                        style={{ fontWeight: "bold" }}
                      >
                        Status:{" "}
                        <span style={{ color: "#BFA75D" }}>
                          {claimStatuses[partner.partner_id]
                            ? "Claimed"
                            : "Available"}
                        </span>
                      </span>{" "}
                    </div>
                    <div className="task_Reward">
                      <span
                        style={{
                          color: "#BFA75D",
                          fontSize: "20px",
                          letterSpacing: "1px",
                        }}
                      >
                        +{formatReward(partner.reward)}{" "}
                        {/* Using formatted reward */}
                      </span>{" "}
                      <br />
                      <span>Task Reward</span>
                    </div>

                    <div className="task_Icon">
                      {claimStatuses[partner.partner_id] ? (
                        <img
                          src={v3_GreenTick}
                          alt=""
                          style={{ height: "25px", width: "25px" }}
                        />
                      ) : (
                        <img
                          src={v3_Arrrow}
                          alt=""
                          style={{ height: "25px", width: "25px" }}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </>
          )}

          {/* <animated.div style={{ ...rightAnimation }}>
            <SwitchableTaskCard screen={tab.toLowerCase()} />
          </animated.div> */}
          {tab === "YouTube" && (
            <animated.div style={{ ...rightAnimation }}>
              <SwitchableTaskCard screen={tab.toLowerCase()} />
            </animated.div>
          )}
          {tab === "tiktok" && (
            <animated.div style={{ ...rightAnimation }}>
              <SwitchableTaskCard screen={tab.toLowerCase()} />
            </animated.div>
          )}

          {/* {tab === "tiktok" && (
            <>
              {partnersData
                .sort((a, b) => {
                  const statusA = claimStatuses[a.partner_id] ? 1 : 0;
                  const statusB = claimStatuses[b.partner_id] ? 1 : 0;
                  return statusA - statusB;
                })
                .map((partner) => (
                  <div
                    key={partner.partner_id}
                    className={`task_Card borderCard ${
                      claimStatuses[partner.partner_id] ? "completed" : ""
                    }`}
                    onClick={() => handlePopupOpen(partner)}
                    style={{ width: "92vw", marginTop: "20px" }}
                  >
                    <div className="taskImg">
                      <img
                        src={partner.partner_image_url}
                        alt={partner.partner_name}
                        height="62px"
                        width="62px"
                        style={{ marginTop: "3px" }}
                      />
                    </div>
                    <div className="task_text">
                      <span className="text_1stSpan">
                        {partner.partner_name}
                      </span>{" "}
                      <br />
                      <span
                        className="text_3rdSpan"
                        style={{ fontWeight: "bold" }}
                      >
                        Status:{" "}
                        <span style={{ color: "#BFA75D" }}>
                          {claimStatuses[partner.partner_id]
                            ? "Claimed"
                            : "Available"}
                        </span>
                      </span>{" "}
                    </div>
                    <div className="task_Reward">
                      <span
                        style={{
                          color: "#BFA75D",
                          fontSize: "20px",
                          letterSpacing: "1px",
                        }}
                      >
                        +{formatReward(partner.reward)}{" "}
                      </span>{" "}
                      <br />
                      <span>Task Reward</span>
                    </div>

                    <div className="task_Icon">
                      {claimStatuses[partner.partner_id] ? (
                        <img
                          src={v3_GreenTick}
                          alt=""
                          style={{ height: "25px", width: "25px" }}
                        />
                      ) : (
                        <img
                          src={v3_Arrrow}
                          alt=""
                          style={{ height: "25px", width: "25px" }}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </>
          )} */}
        </div>
        {/* isPopupOpen */}
        <V3Popup isOpen={isPopupOpen} onClose={handlePopupClose}>
          <DemoPartner
            partner={selectedPartner}
            onClose={handlePopupClose}
            updateClaimStatus={updateClaimStatus}
          />{" "}
          {/* Pass the selected partner */}
        </V3Popup>
      </div>

      <WalletModal
        connectedAddress={connectedAddress}
        showConnectWallet={showConnectWallet}
        setShowConnectWallet={setShowConnectWallet}
      />
      <div className={`bottomNav ${isPopupOpen ? "hidden" : ""}`}>
        <BottomButtons page={page} setPages={setPages} />
      </div>
    </div>
  );
};

function SocialMediaCardSkeleton() {
  return (
    <div className="card socialMediaCardContainer" style={{ height: "65px" }}>
      <div className="shimmer" />
    </div>
  );
}

export default Tasks;
