import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../../context/AuthContext";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import "./scratch.css";

const ScratchGame = ({ handleOpenButton, gameBanner, isAvailable }) => {
  const [tickets, setTickets] = useState(0);
  const [playAvailable, setPlayAvailable] = useState(false);
  const [resRecieved, setResRecieved] = useState(false);
  const [remTime, setRemTime] = useState(0);
  const { jwt } = useContext(AuthContext);

  const [timeLeft, setTimeLeft] = useState(remTime);
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs > 0 ? `${hrs}:` : ""}${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };
  useEffect(() => {
    async function isAvailable() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/puzzle/isAvailable`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        const parsedResponse = await response.json();

        if (parsedResponse.status == "SUCCESS") {
          setPlayAvailable(true);
          setResRecieved(true);
          setTickets(parsedResponse.data.playCount);
          setRemTime(0);
        }
        if (parsedResponse.status == "FAILED") {
          setResRecieved(true);
          setPlayAvailable(false);
          setTickets(
            parsedResponse.error.playCount >= 3
              ? 3
              : parsedResponse.error.playCount
          );
          if (parsedResponse.error.playCount >= 3) {
            setRemTime(parsedResponse.error.lastPlayTime);
          } else {
            setRemTime(60 * 30 - parsedResponse.error.lastPlayTime);
            // setRemTime(60 - parsedResponse.error.lastPlayTime);
          }
        }
      } catch (error) {
        setPlayAvailable(false);
        console.log(error);
      }
    }

    isAvailable();
  }, []);

  useEffect(() => {
    // When remTime changes, reset the timer
    setTimeLeft(remTime);
  }, [remTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval); // Stop the timer when it reaches 0
          if (resRecieved) {
            setPlayAvailable(true);
          }

          return 0; // Ensure timeLeft is set to exactly 0
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [resRecieved]);
  return (
    <div>
      <img src={gameBanner} alt="" className="gameImg" />
      <div style={{ marginTop: "15px" }}>
        <button
          className="stripe-button"
          onClick={() => {
            if (playAvailable) {
              if (navigator.vibrate) {
                navigator.vibrate(50);
              } else if (window.Telegram?.WebApp) {
                window.Telegram?.WebApp.HapticFeedback.notificationOccurred(
                  "success"
                );
              }
              handleOpenButton(); // Only call handleOpenButton if playAvailable is true
            } else {
              // Display the toast error messages based on the tickets
              if (tickets === 3) {
                toast.error("Reached Daily Limit");
              } else {
                toast.error(
                  `Cannot play again for ${Math.ceil(timeLeft / 60)} minutes`
                );
              }
            }
          }}
        >
          {!playAvailable ? formatTime(timeLeft) : "Play Now"}
        </button>
      </div>
    </div>
  );
};

export default ScratchGame;
