import { useSpring } from "@react-spring/web";
import imageCompression from "browser-image-compression";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { UserContext } from "../../../context/UserContext";
import blueDiamondIcon from "../../../media/blueDiamond.png";
import bronzeIcon from "../../../media/bronze.png";
import crownDiamondIcon from "../../../media/crownDiamond.png";
import diamondIcon from "../../../media/diamond.png";
import goldIcon from "../../../media/gold.png";
import platinumIcon from "../../../media/platinum.png";
import silverIcon from "../../../media/silver.png";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import "./profile.css";

import Select from "react-select";
import countryList from "react-select-country-list";

import useTelegramBackButton from "../../../utils/backButton";
import tick from "./Group 50.png";
function parseJSONSafe(string) {
  try {
    const parsed = JSON.parse(string);
    return parsed;
  } catch (error) {
    return {};
  }
}

const rankIcons = {
  1: bronzeIcon,
  2: silverIcon,
  3: goldIcon,
  4: platinumIcon,
  5: diamondIcon,
  6: blueDiamondIcon,
  7: crownDiamondIcon,
};

const Profile = ({ setUserCredits, isOpen, onClose }) => {
  useTelegramBackButton();
  const { setUser, user } = useContext(UserContext);
  const [showCongratsPopup, setShowCongratsPopup] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [country, setCountry] = useState(parseJSONSafe(user?.country));
  const fileInputRef = useRef();
  const [profileImgUrl, setProfileImgUrl] = useState(user.profileImgUrl);
  const [selectedFile, setSelectedFile] = useState(null);
  const [page, setPages] = useState("profile");
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    country: parseJSONSafe(user?.country) || "",
    twitterUsername: user?.twitterUsername || "",
    telegramUsername: user?.telegramUsername || "",
    walletAddress: user?.walletAddress || "",
    task: user?.task || {},
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Use the AuthContext to get the JWT token
  const { jwt } = useContext(AuthContext);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (isOpen) {
      // Delay adding the "show" class to ensure smooth animation
      setTimeout(() => setShowPopup(true), 50);
    } else {
      setShowPopup(false);
    }
  }, [isOpen]);

  useEffect(() => {
    // Event listener for clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Close the popup if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const [twitterUsername, setTwtitterUsername] = useState(
    user?.twitterUsername
  );
  const [telegramUsername, setTelegramUsername] = useState(
    user?.telegramUsername
  );
  const [walletAddress, setWalletAddress] = useState(user?.walletAddress);
  const [isLoading, setIsLoading] = useState(false);
  const [task, setTask] = useState({});
  const [visible, setVisible] = useState(false);

  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setCountry(value);
    console.log(value);
  };

  const isEdited =
    name !== user?.name ||
    email !== user?.email ||
    country !== user?.country ||
    twitterUsername !== user?.twitterUsername ||
    telegramUsername !== user?.telegramUsername ||
    walletAddress !== user?.walletAddress;

  const animationConfig = (duration) => ({
    from: { transform: "translateX(-100%)" },
    to: { transform: visible ? "translateX(0%)" : "translateX(-100%)" },
    config: { duration: duration },
  });

  const animations = [
    useSpring(animationConfig(400)),
    useSpring(animationConfig(450)),
    useSpring(animationConfig(500)),
    useSpring(animationConfig(550)),
    useSpring(animationConfig(600)),
    useSpring(animationConfig(650)),
  ];

  useEffect(() => {
    setVisible(true);
    async function fetchTask() {
      try {
        setIsLoading(true);
        const response = await fetch(`${SERVER_ENDPOINT}/v1/task/list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ screen: "profile" }),
        });
        const parsedResponse = await response.json();
        if (parsedResponse.status === "SUCCESS") {
          setTask(parsedResponse.data[0]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchTask();
  }, []);

  useEffect(() => {
    if (!jwt) {
      navigate("/");
    }
  }, [jwt]);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    // Define compression options
    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: file?.width || 1024,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImgUrl(reader?.result);
      };
      reader?.readAsDataURL(compressedFile);
    } catch (error) {
      console.error("Error during file upload and compression:", error);
    }
  };

  async function handleKycSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    setStatusMessage("");

    try {
      const formDataToSend = new FormData();
      if (selectedFile) formDataToSend.append("profileImg", selectedFile);

      // Append all form data
      Object.keys(formData).forEach((key) => {
        if (formData[key]) {
          if (key === "country") {
            formDataToSend.append(key, JSON.stringify(country));
          } else {
            formDataToSend.append(key, formData[key]);
          }
        }
      });

      if (task.taskID) {
        formDataToSend.append("taskID", task.taskID);
      }

      const response = await fetch(`${SERVER_ENDPOINT}/v1/user/kyc`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        body: formDataToSend,
      });

      const parsedResponse = await response.json();

      if (parsedResponse.status === "SUCCESS") {
        const { credits, kycCompleted, ...rest } = parsedResponse.data;

        // Update user context and show success message
        setUser((prev) => ({ ...prev, ...rest }));
        setStatusMessage("KYC submission successful!");

        // Handle task completion and rewards
        if (!task.completed && kycCompleted) {
          setTask({ ...task, completed: true });
          setUserCredits(parseInt(credits));
          setShowCongratsPopup(true);
          setShowConfetti(true);

          setTimeout(() => {
            setShowCongratsPopup(false);
            setShowConfetti(false);
          }, 6000);
        } else {
          toast.success("Profile updated");
        }
      } else {
        throw new Error(parsedResponse.message || "Unable to save details");
      }
    } catch (error) {
      console.error("KYC submission error:", error);
      setStatusMessage(`Error: ${error.message}`);
      toast.error("Failed to submit KYC");
    } finally {
      setIsSubmitting(false);
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#333333",
      color: "white",
      border: "none",
      outline: "none",
      borderRadius: "10px",
      width: "16rem",
      height: "3rem",
      marginBottom: "1rem",
      "&:hover": {
        border: "1px solid #32303e",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      background: "#1D1C1D",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#32303e" : "#1e1d20",
      color: "white",
      "&:hover": {
        background: "#32303e",
        outline: "none", // Remove outline on hover
      },
    }),
  };

  const springProps = useSpring({
    transform: isOpen ? "translateY(0%)" : "translateY(-100%)",
  });

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div
        ref={popupRef}
        className={`popup-container ${showPopup ? "show" : ""}`}
        style={{
          backgroundColor: "#1D1C1D",
          borderTopLeftRadius: "60px",
          borderTopRightRadius: "60px",
          borderTop: "5px solid yellow",
        }}
      >
        <div
          style={{
            margin: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "30px",
              marginRight: "1rem",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span>KYC</span>
            <img
              alt="tick"
              src={tick}
              style={{ height: "30px", width: "30px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ color: "white", fontSize: "15px" }}>
            upon successful completed the KYC will be rewarded
          </span>
          <span style={{ color: "white", fontSize: "12px" }}>
            with 50K tap credits and blue tick
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <form onSubmit={handleKycSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Username"
              value={formData.name}
              onChange={handleChange}
              style={{
                backgroundColor: "#333333",
                color: "white",
                padding: "10px",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                width: "15rem",
                height: "2rem",
                marginBottom: "1rem",
              }}
            />
            <input
              type="text"
              name="telegramUsername"
              placeholder="Telegram @username"
              value={formData.telegramUsername}
              onChange={handleChange}
              style={{
                backgroundColor: "#333333",
                color: "white",
                padding: "10px",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                width: "15rem",
                height: "2rem",
                marginBottom: "1rem",
              }}
            />
            <input
              type="text"
              name="twitterUsername"
              placeholder="X@username"
              value={formData.twitterUsername}
              onChange={handleChange}
              style={{
                backgroundColor: "#333333",
                color: "white",
                padding: "10px",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                width: "15rem",
                height: "2rem",
                marginBottom: "1rem",
              }}
            />
            {/* ========test */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Select
                options={options}
                value={country}
                onChange={changeHandler}
                styles={customStyles}
              />
            </div>
            {/* <input
              type="text"
              name="country"
              placeholder="Country"
              value={formData.country.label || ""}
              onChange={handleChange}
              style={{
                backgroundColor: "#333333",
                color: "white",
                padding: "10px",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                width: "15rem",
                height: "2rem",
                marginBottom: "1rem",
              }}
            /> */}
            {/* ============== */}
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              value={formData.email}
              onChange={handleChange}
              style={{
                backgroundColor: "#333333",
                color: "white",
                padding: "10px",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                width: "15rem",
                height: "2rem",
                marginBottom: "1rem",
              }}
            />

            {statusMessage && (
              <div
                style={{
                  color: statusMessage.includes("Error") ? "red" : "green",
                  marginBottom: "1rem",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {statusMessage}
              </div>
            )}

            <button
              type="submit"
              disabled={isSubmitting || !isEdited}
              style={{
                marginLeft: "12rem",
                backgroundColor: "#333333",
                padding: "10px",
                border: "none",
                outline: "none",
                color: "#FFA114",
                borderRadius: "10px",
                marginBottom: "1rem",
                cursor: isSubmitting || !isEdited ? "not-allowed" : "pointer",
                opacity: isSubmitting || !isEdited ? 0.7 : 1,
              }}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>

        {!task.completed && (
          <button
            disabled={!isEdited}
            style={{
              backgroundColor: "#FFA114" /* Background color */,
              padding: "10px" /* Padding for better look */,
              border: "none" /* Remove border */,
              // borderRadius: "5px" /* Optional rounded corners */,
              outline: "none",
              color: "white",
              width: "10rem",
              height: "3rem",
              borderRadius: "10px",
              marginBottom: "1rem",
            }}
          >
            CLAIM
          </button>
        )}
      </div>
    </div>
  );
};

export default Profile;
